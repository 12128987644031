import React, { useMemo } from 'react';
import {
    ListBase, TextInput, DateInput, Title, Pagination
} from 'react-admin';

import PackageList from 'APP/components/package/PackageList';
import ExportLinkButton from 'APP/components/ExportLinkButton';
import SizeSelect from 'APP/components/package/SizeSelect';
import BrandSelect from 'APP/components/package/BrandSelect';
import ExportCustomsSelect from 'APP/components/package/ExportCustomsSelect';
import CustomTopToolbar from 'APP/components/CustomTopToolbar';
import tabConfig from 'APP/configs/tabConfig';

export default function List () {

    const tabs = [
        { id: 'TW' },
        { id: 'HK' },
        { id: 'MY' },
        { id: 'SG' },
        { id: 'VN' },
        { id: 'PH' },
    ];

    // Filters
    const filters = useMemo(() => [
        <TextInput source="subOrderId" type="number" label="訂單商品編號" alwaysOn key="search" resettable />,
        <DateInput source="startDate" label="賣家出貨日期開始" alwaysOn key="startDate" parse={(value) => new Date(value).getTime()} />,
        <DateInput source="endDate" label="賣家出貨日期結束" alwaysOn key="endDate" parse={(value) => new Date(value).getTime()} />,
        <TextInput source="product.sku" label="型號" key="product.sku" resettable />,
        <BrandSelect source="product.brand.id" key="product.brand.name" label="品牌" status={tabConfig[tabs[0].id].packageStatus} />,
        <TextInput source="product.title" label="商品名稱" key="product.title" resettable />,
        <SizeSelect source="product.size.id" key="product.size.sizeInfo.kind" label="尺寸" status={tabConfig[tabs[0].id].packageStatus} />,
        <ExportCustomsSelect source="customsInfo.exportCustomsType" key="customsInfo.exportCustomsType" label="報關方式" />,
        <TextInput source="packageNumber" type="number" label="貨件編號" key="packageNumber" resettable />,
    ], []);

    return (
        <ListBase
            filterDefaultValues={{
                status: tabConfig[tabs[0].id].packageStatus,
                destinationCountryCode: tabConfig[tabs[0].id].destinationCountryCode
            }}
            perPage={25}
            sort={tabConfig[tabs[0].id].sort}
        >
            <Title defaultTitle="商品驗證" />
            <CustomTopToolbar filters={filters}>
                <ExportLinkButton to="https://lookerstudio.google.com/u/0/reporting/9487e2df-ebb9-483b-9b23-00b49a83b7ab/page/p_wud4fcjxad" />
            </CustomTopToolbar>
            <PackageList tabs={tabs} countFetchApi="statistic/count/boxing" />
            <Pagination rowsPerPageOptions={[25, 50, 100]} />
        </ListBase>
    );

}
