import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Datagrid, TextField, useListContext, useUnselectAll
} from 'react-admin';
import { Box } from '@mui/material';

import PackageTabs from 'APP/components/package/PackageTabs';
import UpdatePackagesButton from 'APP/components/package/UpdatePackagesButton';
import SubOrderLinkField from 'APP/components/field/SubOrderLinkField';
import SellerLinkField from 'APP/components/field/SellerLinkField';
import ProductField from 'APP/components/field/ProductField';
import SizeField from 'APP/components/field/SizeField';
import StatusField from 'APP/components/field/StatusField';
import CustomDateField from 'APP/components/field/CustomDateField';
import ExportCustomField from 'APP/components/field/ExportCustomField';
import ActionButtons from 'APP/components/ActionButtons';
import tabConfig from 'APP/configs/tabConfig';
import useCustomList from 'APP/hooks/useCustomList';

const exportCustomsTypeTabMap = ['TW', 'HK', 'MY', 'SG', 'VN', 'PH', 'boxed', 'shipping', 'completed'];

export default function PackageList ({ tabs, countFetchApi }) {

    const context = useListContext();
    const unselectAll = useUnselectAll('package');
    const { filterValues, isFetching } = context;

    const currentTabId = useMemo(() => Object.keys(tabConfig).find((key) => (
        filterValues.status?.join() === tabConfig[key].packageStatus.join()
         && filterValues?.cancelType?.join() === tabConfig[key]?.cancelType?.join()
         && filterValues?.destinationCountryCode === tabConfig[key]?.destinationCountryCode
    )), [filterValues?.cancelType, filterValues?.status, filterValues?.destinationCountryCode]);

    const [tabId, setTabId] = useState(currentTabId);

    const { data: exportTypeData } = useCustomList(exportCustomsTypeTabMap.includes(tabId) ? 'customs/export_customs_type/all' : null);

    useEffect(() => {

        unselectAll();
        if (tabId !== currentTabId) setTabId(currentTabId);
    
    }, [filterValues]);

    return (
        <Box
            sx={{
                borderLeft: 1,
                borderRight: 1,
                borderTop: 1,
                borderColor: 'divider',
                borderRadius: 2,
                overflow: 'hidden'
            }}
        >
            <PackageTabs
                tabs={tabs}
                tabId={tabId}
                setTabId={setTabId}
                listIsFetching={isFetching}
                countFetchApi={countFetchApi}
            />
            {
                (tabId === 'pendingStorage')
                    && (
                        <Datagrid
                            bulkActionButtons={false}
                            optimized
                        >
                            <ActionButtons />
                            <CustomDateField date="createdAt" label="賣家出貨日期" sortBy="createdAt" />
                            <SubOrderLinkField label="訂單商品編號" sortBy="subOrderId" />
                            <TextField source="product.sku" label="型號" />
                            <TextField source="product.brand.name" label="品牌" sortable={false} />
                            <ProductField label="商品名稱" sortable={false} />
                            <SizeField label="尺寸" sortable={false} />
                            <TextField source="seller.id" label="賣家會員ID" />
                            <SellerLinkField label="賣家姓名" sortBy="seller.name" sortable={false} />
                        </Datagrid>
                    )
            }
            {
                (tabId === 'inStock')
                    && (
                        <Datagrid bulkActionButtons={false} optimized>
                            <ActionButtons />
                            <CustomDateField date="inStockAt" label="入庫日期" sortBy="inStockAt" />
                            <TextField source="packageNumber" label="貨件編號" />
                            <SubOrderLinkField label="訂單商品編號" sortBy="subOrderId" />
                            <StatusField label="處理狀態" sortable={false} />
                            <TextField source="product.sku" label="型號" sortable={false} />
                            <TextField source="product.brand.name" label="品牌" sortable={false} />
                            <ProductField label="商品名稱" />
                            <SizeField label="尺寸" sortable={false} />
                            <TextField source="seller.id" label="賣家會員ID" />
                            <SellerLinkField label="賣家姓名" sortBy="seller.name" sortable={false} />
                            <TextField source="operator.name" label="簽收者" sortable={false} />
                        </Datagrid>
                    )
            }
            {
                (tabId === 'inStockException')
                    && (
                        <Datagrid bulkActionButtons={false} optimized>
                            <ActionButtons />
                            <CustomDateField date="createdAt" label="賣家出貨日期" sortBy="createdAt" />
                            <TextField source="packageNumber" label="貨件編號" />
                            <SubOrderLinkField label="訂單商品編號" sortBy="subOrderId" />
                            <StatusField label="處理狀態" sortable={false} />
                            <TextField source="product.sku" label="型號" />
                            <TextField source="product.brand.name" label="品牌" sortable={false} />
                            <ProductField label="商品名稱" sortable={false} />
                            <SizeField label="尺寸" sortable={false} />
                            <TextField source="seller.id" label="賣家會員ID" />
                            <SellerLinkField label="賣家姓名" sortBy="seller.name" sortable={false} />
                            <TextField source="cancelReason.reason" label="異常原因" sortable={false} />
                            <TextField source="operator.name" label="更新者" sortable={false} />
                        </Datagrid>
                    )
            }
            {
                (tabId === 'pendingVerification')
                    && (
                        <Datagrid
                            bulkActionButtons={<UpdatePackagesButton text="批次更新至驗證中" status={10} />}
                            optimized
                        >
                            <ActionButtons />
                            <CustomDateField date="inStockAt" label="入庫日期" sortBy="inStockAt" />
                            <SubOrderLinkField label="訂單商品編號" sortBy="subOrderId" />
                            <TextField source="packageNumber" label="貨件編號" />
                            <StatusField label="處理狀態" sortable={false} />
                            <TextField source="product.sku" label="型號" />
                            <TextField source="product.brand.name" label="品牌" sortable={false} />
                            <ProductField label="商品名稱" sortable={false} />
                            <SizeField label="尺寸" sortable={false} />
                            <TextField source="shelves.name" label="貨架" sortable={false} />
                            <TextField source="operator.name" label="更新者" sortable={false} />
                        </Datagrid>
                    )
            }
            {
                (tabId === 'verifying' || tabId === 'verificationFailed')
                    && (
                        <Datagrid bulkActionButtons={false} optimized>
                            <ActionButtons />
                            <CustomDateField date="inStockAt" label="入庫日期" sortBy="inStockAt" />
                            <SubOrderLinkField label="訂單商品編號" sortBy="subOrderId" />
                            <TextField source="packageNumber" label="貨件編號" />
                            <StatusField label="處理狀態" sortable={false} />
                            <TextField source="product.sku" label="型號" />
                            <TextField source="product.brand.name" label="品牌" sortable={false} />
                            <ProductField label="商品名稱" sortable={false} />
                            <SizeField label="尺寸" sortable={false} />
                            <TextField source="operator.name" label="更新者" sortable={false} />
                        </Datagrid>
                    )
            }
            {
                (tabId === 'TW' || tabId === 'HK' || tabId === 'MY' || tabId === 'SG' || tabId === 'VN' || tabId === 'PH')
                    && (
                        <Datagrid bulkActionButtons={false}>
                            <ActionButtons />
                            <CustomDateField date="verifyAt" label="驗證日期" sortBy="verifyAt" />
                            <SubOrderLinkField label="訂單商品編號" sortBy="subOrderId" />
                            <TextField source="packageNumber" label="貨件編號" />
                            <StatusField label="處理狀態" sortable={false} />
                            <TextField source="recipientInfo.country" label="寄送目的地" sortable={false} />
                            <ExportCustomField source="customsInfo.exportCustomsType" label="報關方式" sortable={false} types={exportTypeData} />
                            <TextField source="product.sku" label="型號" />
                            <TextField source="product.brand.name" label="品牌" sortable={false} />
                            <ProductField label="商品名稱" sortable={false} />
                            <SizeField label="尺寸" sortable={false} />
                            <TextField source="operator.name" label="更新者" sortable={false} />
                        </Datagrid>
                    )
            }
            {
                (tabId === 'boxed')
                    && (
                        <Datagrid
                            bulkActionButtons={<UpdatePackagesButton text="批次更新至已出庫" status={50} />}
                        >
                            <ActionButtons />
                            <CustomDateField date="shippingInfo.fetchNumberAt" label="取號時間" sortBy="shippingInfo.fetchNumberAt" />
                            <SubOrderLinkField label="訂單商品編號" sortBy="subOrderId" />
                            <TextField source="packageNumber" label="貨件編號" />
                            <StatusField label="處理狀態" sortable={false} />
                            <TextField source="shippingInfo.shippingName" label="物流商" sortable={false} />
                            <TextField source="recipientInfo.country" label="寄送目的地" sortable={false} />
                            <TextField source="shippingInfo.shippingNumber" label="運單編號" sortable={false} />
                            <ExportCustomField source="customsInfo.exportCustomsType" label="報關方式" sortable={false} types={exportTypeData} />
                            <TextField source="product.sku" label="型號" />
                            <TextField source="product.brand.name" label="品牌" sortable={false} />
                            <ProductField label="商品名稱" sortable={false} />
                            <SizeField label="尺寸" sortable={false} />
                            <TextField source="operator.name" label="更新者" sortable={false} />
                        </Datagrid>
                    )
            }
            {
                (tabId === 'shipping' || tabId === 'completed')
                    && (
                        <Datagrid
                            bulkActionButtons={false}
                        >
                            <ActionButtons />
                            <CustomDateField date="outOfStockAt" label="出庫日期" sortBy="outOfStockAt" />
                            <SubOrderLinkField label="訂單商品編號" sortBy="subOrderId" />
                            <TextField source="packageNumber" label="貨件編號" />
                            <StatusField label="處理狀態" sortable={false} />
                            <TextField source="shippingInfo.shippingName" label="物流商" sortable={false} />
                            <TextField source="recipientInfo.country" label="寄送目的地" sortable={false} />
                            <TextField source="shippingInfo.shippingNumber" label="運單編號" sortable={false} />
                            <ExportCustomField source="customsInfo.exportCustomsType" label="報關方式" sortable={false} types={exportTypeData} />
                            <TextField source="product.sku" label="型號" />
                            <TextField source="product.brand.name" label="品牌" sortable={false} />
                            <ProductField label="商品名稱" />
                            <SizeField label="尺寸" sortable={false} />
                            <TextField source="operator.name" label="更新者" sortable={false} />
                        </Datagrid>
                    )
            }
            {
                (tabId === 'returnToSeller' || tabId === 'exception' || tabId === 'dts')
                    && (
                        <Datagrid
                            bulkActionButtons={false}
                            optimized
                        >
                            <ActionButtons />
                            <CustomDateField date="inStockAt" label="入庫時間" sortBy="inStockAt" />
                            <SubOrderLinkField label="訂單商品編號" sortBy="subOrderId" />
                            <TextField source="packageNumber" label="貨件編號" />
                            <StatusField label="處理狀態" sortable={false} />
                            <TextField source="product.sku" label="型號" />
                            <TextField source="product.brand.name" label="品牌" sortable={false} />
                            <ProductField label="商品名稱" />
                            <SizeField label="尺寸" sortable={false} />
                            <SellerLinkField label="賣家姓名" sortBy="seller.name" />
                            <TextField source="cancelReason.reason" label="異常原因" sortable={false} />
                            <TextField source="operator.name" label="更新者" sortable={false} />
                        </Datagrid>
                    )
            }
        </Box>
    );

}

PackageList.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
        })
    ).isRequired,
    countFetchApi: PropTypes.string.isRequired
};
