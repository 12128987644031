import React from 'react';
import { useRecordContext } from 'react-admin';

function SellerLinkField (props) {

    const record = useRecordContext(props);
    const erpUrl = process.env.REACT_APP__ERP_URL;

    return (
        <a href={`${erpUrl}/user-info?id=${record.seller.id}&tab=2`} target="_blank" rel="noreferrer">
            {record.seller.name}
        </a>
    );

}

export default React.memo(SellerLinkField);
