// Tab 對應的 status, sort
const tab = {
    pendingStorage: {
        name: '待入庫',
        packageStatus: [0],
        sort: { field: 'createdAt', order: 'DESC' }
    },
    inStock: {
        name: '已入庫',
        packageStatus: [1, 10, 20, 30, 90],
        cancelType: ['incorrect_product_from_seller', 'not_authenticated', 'seller_canceled', 'buyer_canceled', 'product_damange', 'product_loss_in_stock', 'other'],
        sort: { field: 'inStockAt', order: 'DESC' }
    },
    inStockException: {
        name: '入庫異常',
        packageStatus: [90],
        cancelType: ['seller_not_send'],
        sort: { field: 'createdAt', order: 'DESC' }
    },
    pendingVerification: {
        name: '待驗證',
        packageStatus: [1],
        sort: { field: 'inStockAt', order: 'DESC' }
    },
    verifying: {
        name: '商品驗證中',
        packageStatus: [10],
        sort: { field: 'inStockAt', order: 'DESC' }
    },
    verificationFailed: {
        name: '驗證不通過',
        packageStatus: [90],
        cancelType: ['not_authenticated'],
        sort: { field: 'inStockAt', order: 'DESC' }
    },
    verified: {
        name: '待包裝取號',
        packageStatus: [20],
        sort: { field: 'verifyAt', order: 'DESC' }
    },
    TW: {
        name: '台灣國內',
        packageStatus: [20],
        destinationCountryCode: 'TW',
        sort: { field: 'verifyAt', order: 'DESC' }
    },
    HK: {
        name: '香港',
        packageStatus: [20],
        destinationCountryCode: 'HK',
        sort: { field: 'verifyAt', order: 'DESC' }
    },
    MY: {
        name: '馬來西亞',
        packageStatus: [20],
        destinationCountryCode: 'MY',
        sort: { field: 'verifyAt', order: 'DESC' }
    },
    SG: {
        name: '新加坡',
        packageStatus: [20],
        destinationCountryCode: 'SG',
        sort: { field: 'verifyAt', order: 'DESC' }
    },
    VN: {
        name: '越南',
        packageStatus: [20],
        destinationCountryCode: 'VN',
        sort: { field: 'verifyAt', order: 'DESC' }
    },
    PH: {
        name: '菲律賓',
        packageStatus: [20],
        destinationCountryCode: 'PH',
        sort: { field: 'verifyAt', order: 'DESC' }
    },
    boxed: {
        name: '待出庫',
        packageStatus: [30],
        sort: { field: 'shippingInfo.fetchNumberAt', order: 'DESC' }
    },
    shipping: {
        name: '已出庫',
        packageStatus: [50, 60],
        sort: { field: 'outOfStockAt', order: 'DESC' }
    },
    completed: {
        name: '已完成',
        packageStatus: [70],
        sort: { field: 'outOfStockAt', order: 'DESC' }
    },
    exception: {
        name: '待退回賣家',
        packageStatus: [90],
        cancelType: ['incorrect_product_from_seller', 'not_authenticated', 'seller_canceled', 'buyer_canceled', 'product_damange', 'product_loss_in_stock', 'other'],
        sort: { field: 'inStockAt', order: 'DESC' }
    },
    returnToSeller: {
        name: '已退回賣家',
        packageStatus: [99],
        sort: { field: 'inStockAt', order: 'DESC' }
    },
    dts: {
        name: '包退入倉',
        packageStatus: [90],
        cancelType: ['buyer_cancel_dts'],
        sort: { field: 'inStockAt', order: 'DESC' }
    }
};

export default tab;
