import React from 'react';
import { useEditContext } from 'react-admin';

const erpUrl = process.env.REACT_APP__ERP_URL;

function SellerInfo () {

    const { record } = useEditContext();

    return (
        <div className="sellerInfo">
            <div className="title">賣家資訊：</div>
            {/* link to ERP 賣家資料頁面 */}
            <a href={`${erpUrl}/user-info?id=${record?.seller?.id}&tab=2`} target="_blank" rel="noreferrer">{record?.seller?.id}</a>
            <div>{record?.seller?.name}</div>
            <div>{record?.seller?.phone}</div>
            <div>{record?.seller?.email}</div>
        </div>
    );

}

export default SellerInfo;
